/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import globalState from "../store";
import { button, containerCentered, input } from "./styles";
import { getDefaultIndustry } from "../utils/server/industry";
import { IIndustry } from "../types/types";
import formatLandingPage, {
  getTestimonialsWithDraggableId,
} from "../utils/helpers/formatLandingPage";

const defaultAtomState = {
  sitePk: 0 as number,
  id_key: "",
  cover_upload: "",
  multipage: true,
  company_name: "",
  company_tagline: "",
  company_type: "",
  slug: "", // turn this into a ternery like so: route.params.slug ? route.params.slug : ''
  hero_gallery_photos: [],
  hero_image: "",
  // "hero_image": [],
  // "hero_type": '',
  // "unsplash": '',
  logo: "",
  custom_primary: "",
  // "shade":  '',
  custom_secondary: "",
  // "secondary_shade":  '',
  phone: "",
  email: "",
  license_number: "",
  insured: false,
  bonded: false,
  emergency: false,
  veteran: false,
  family: false,
  local: false,
  about: "",
  about_us_image: "",
  // "about_us_type": '',
  twitter: "",
  instagram: "",
  facebook: "",
  google: "",
  // "snapchat": '',
  youtube: "",
  // "linkedin": '',
  nextdoor: "",
  tiktok: "",
  // "etsy": '',
  yelp: "",
  // "thumbtack": '',
  // "featured_video": '',
  city: "",
  state: "",
  // "address":  '',
  services: [],
  testimonials: [],
  faqs: [],
  gallery: [],
  gallery_names: [],
  // "product": [],
  // "awards": [],
  about_header: "",
  service_header: "",
  service_subtitle: "",
  work_header: "",
  work_subtitle: "",
  partners_header: "",
  product_header: "",
  product_subtitle: "",
  // "award_header":  '',
  testimonial_header: "",
  testimonial_subtitle: "",
  paid_url: "",
  github_repo: "",
  external_link: "",
  // "company_type":  '',
  business_hours: [],
  address: "",
  service_area_array: [],
  service_areas: [],
  // "galleries": []
  // "fbData": {} as any,
  galleries: [],
  fbData: {
    about: "",
    address: "",
    company_name: "",
    company_type: "",
    logo: "",
    phone: "",
    extraPhotos: [],
    currentPulledPhotoCount: 0,
    photos: [],
    services: [],
    socialMediaUrls: "",
    testimonials: [],
  },
  review_link: "",
  leave_review_link_header: "",
  review_link_more: "",
  see_more_reviews_header: "",
  alternative_review_html: "",
  cta_main: "Text",
  cta_main_1: "Call",
  cta_nav: "Link",
  cta_slide_in: "Email",
  cta_main_link: "",
  cta_main_1_link: "",
  cta_nav_link: "",
  cta_slide_in_link: "",
  call_to_action_message: "Send us a Text",
  call_to_action_message_main_1: "Give us a Call",
  call_to_action_message_nav: "Book Now",
  call_to_action_message_slide_in: "Email Us",
  home_nav_title: "Home",
  service_nav_title: "Services",
  gallery_nav_title: "Galleries",
  reviews_nav_title: "Reviews",
  gallery_all_title: "All Photos",
  socials_header: "",
  three_step_header: "",
  three_step_subtitle: "",
  three_step_1_header: "",
  three_step_1_subtitle: "",
  three_step_2_header: "",
  three_step_2_subtitle: "",
  three_step_3_header: "",
  three_step_3_subtitle: "",
  // partners_header:'',
  payment_header: "",
  payment_subtitle: "",
  faq_header: "",
  team_header: "",
  team_subtitle: "",
  team_nav_title: "Team",
  jobs_header: "",
  jobs_subtitle: "",
  jobs_nav_title: "Jobs",
  cta_header: "",
  cta_subtitle: "",
  final_cta_header: "",
  final_cta_subtitle: "",
  call_to_action_message_nav_1: "",
  call_to_action_message_recruiting: "",
  cta_nav_1: "",
  cta_recruiting: "",
  cta_nav_1_link: "",
  cta_recruiting_link: "",
  cta_get_a_quote: "",
  cta_get_a_quote_link: "",
  call_to_action_message_get_a_quote: "",
  payments: [],
  awards: [],
  deal_header: "",
  deal_subtitle: "",
  deal_button_text: "",
  deal_button_link: "",
  teammates: [],
  team_col_1_header: "",
  team_col_1: "",
  team_col_2_header: "",
  team_col_2: "",
  black_owned: false,
  native_owned: false,
  woman_owned: false,
  latino_owned: false,
  bbb_attribute: false,
  leave_review_link_3_header: "",
  leave_review_link_3: "",
  thumbtack: "",
  bbb: "",
  homeadvisor: "",
  find_header: "",
  jobs_description: "",
  // alternative_review_js: '',
  years_in_business_attribute: "",
  products: [],
  version: "",
  show_jobs_page: false,
  show_team_page: false,
  text_show_address: true,
  text_show_attachment: false,
  text_show_email: true,
  text_show_full_name: true,
  text_show_message: true,
  text_message_placeholder: "How can we help?",
  text_show_phone: true,
  text_show_promo_code: false,
  text_show_requested_date: true,
  text_show_services: true,
  prophone_contact_id: "",
  google_analytics_tag: "",
  google_analytics_tag_body: "",
  scheduled_social_post_active: true,
  custom_hero_background_transparency: "25",
  custom_services_page_background_transparency: "25",
  custom_reviews_page_background_transparency: "25",
  custom_gallery_page_background_transparency: "25",
  custom_cta_background_transparency: "25",
  custom_final_cta_background_transparency: "25",
  custom_hero_text_color: "text-white",
  custom_services_page_text_color: "text-white",
  custom_reviews_page_text_color: "text-white",
  custom_gallery_page_text_color: "text-white",
  custom_cta_text_color: "text-white",
  custom_final_cta_text_color: "text-white",
  custom_logo_size: "160",
  custom_hero_font: "",
  custom_all_font: "",
  service_packages_array: [],
};

const NewSite: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [facebookSlug, setFacebookSlug] = useState("");
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [industry, setIndustry] = useState("");
  const [filteredIndustries, setFilteredIndustries] = useState<IIndustry[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const [returnedSlug, setReturnedSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function getIndustries() {
    const defaultIndustries = await getDefaultIndustry();
    setIndustries(defaultIndustries);
    setFilteredIndustries(defaultIndustries);
  }

    // Click-away handler
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setDropdownVisible(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    const filtered = industries.filter((i) =>
      i.industry_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredIndustries(filtered);
  }, [searchTerm, industries]);


  const handleSelectIndustry = (id: string, name: string) => {
    setIndustry(id);
    setSearchTerm(name);
    setDropdownVisible(false);
  };

  useEffect(() => {
    setGlobalState({ ...globalStateAtom, slug: returnedSlug });
  }, [returnedSlug]);

  const handleMakeNewStock = async () => {
    handleMakeNew(true);
  };

  const handleMakeNew = async () => {
    setErrorMessage("");
    localStorage.setItem("globalState", "");

    try {
      setError(false);
      setErrorMessage("");
      localStorage.setItem("globalState", "");
      setLoading(true);

      const presetsResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/get_create_site_presets/${industry}`,
        { fbSlug: facebookSlug }
      );
      const presets = presetsResponse.data.message;

      const createSiteData = {
        fbSlug: facebookSlug,
        industry,
        presets,
      };

      const createSiteResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/createSiteEndpoint`,
        createSiteData
      );

      const { pythonCreateStatus, pythonCreateMessage, siteSlug } =
        createSiteResponse.data;

      if (pythonCreateStatus === 500) {
        setErrorMessage(pythonCreateMessage);
      } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
        await refetchAllData(siteSlug);
        setReturnedSlug(siteSlug);
        history.push(`/logo/${siteSlug}`);
      }

      setLoading(false);
      return "";
    } catch (e) {
      const errorResponse = e as any;
      const errorMessage = errorResponse?.response?.data?.message;

      setError(true);
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  };

  const getFormattedLandingPageData = async (slug: string) => {
    try {
      const fetchedDataResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/getLandingPage/${slug}`
      );

      const fetchedData = fetchedDataResponse.data.message;

      const formattedData = await formatLandingPage(fetchedData);

      formattedData.service_areas.sort((a: any, b: any) => a.order - b.order);
      formattedData.service_area_array.sort((a: any, b: any) => {
        a[a.length - 1] - b[b.length - 1];
      });

      formattedData.testimonials = getTestimonialsWithDraggableId(
        formattedData.testimonials
      );

      return formattedData;
    } catch (e) {
      alert(`Error getting landing page data for slug '${slug}': ${e.message}`);
      return null;
    }
  };

  const handleUpdateGlobalStore = (formattedData: any) => {
    setGlobalState({
      ...formattedData,
      multipage: "multipage" in formattedData ? formattedData.multipage : true,
    });
  };

  async function fetchAllData(slug) {
    const formattedData = await getFormattedLandingPageData(slug);
    handleUpdateGlobalStore(formattedData);
  }

  async function refetchAllData(slug) {
    localStorage.setItem("globalState", "");
    await fetchAllData(slug);
  }

  return (
    <>
      {window.location.href.includes("dev.") ||
      window.location.href.includes("localhost") ||
      window.location.href.includes("127.0.0.1") ? (
        <NavLink
          className="absolute left-3 top-3 border-b-2"
          to={`/lead_generation`}
        >
          Lead Generation
        </NavLink>
      ) : (
        <></>
      )}

      <div className={containerCentered}>
        {errorMessage !== "" ? <div>{errorMessage}</div> : ""}
        {loading && !error ? (
          <img src="/prophone-load.gif" width="50" className="-mt-16" />
        ) : (
          <img src="prophone_favicon_square.png" width="50" className="mb-2" />
        )}
        <h2 className="text-3xl">
          Creat{loading && !error ? "ing" : "e"} a Site
        </h2>
        <input
          onChange={(e) => setFacebookSlug(e.target.value)}
          placeholder="Facebook Slug"
          className={input}
        />
        <div style={{ position: "relative" }} ref={dropdownRef}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setDropdownVisible(true)}
            placeholder="Search for an industry"
            className={input}
          />
          {dropdownVisible && filteredIndustries.length > 0 && (
            <ul
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1000,
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {filteredIndustries.map((i) => (
                <li
                  key={i.id}
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                  }}
                  onClick={() => handleSelectIndustry(i.id.toString(), i.industry_name)}
                >
                  {i.industry_name}
                </li>
              ))}
            </ul>
          )}
        </div>
        {!loading && (
          <>
            <button
              onClick={() => handleMakeNew()}
              className={button}
              disabled={loading}
            >
              Create
            </button>
            <span>or</span>
            <button
              onClick={handleMakeNewStock}
              className={button}
              disabled={loading}
            >
              Create Stock
            </button>
          </>
        )}
        {error && (
          <p className="text-red-500">
            There was an error building this website. Please try again after
            yelling at Dylan.
          </p>
        )}
      </div>
    </>
  );
};

export default NewSite;
